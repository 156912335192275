import module from './Arrow.module.scss';




import React from 'react'

function Arrow() {
  return (
    <svg className={module.arrows}>
        <path className={module.a1} d="M0 0 L30 32 L60 0"></path>
        <path className={module.a2} d="M0 20 L30 52 L60 20"></path>
        <path className={module.a3} d="M0 40 L30 72 L60 40"></path>
    </svg>
  )
}

export default Arrow